import { A } from '@solidjs/router';
import { type Nodes } from 'hast';
import { toJsxRuntime, type Options } from 'hast-util-to-jsx-runtime';
import { toString } from 'hast-util-to-string';
import { Show, splitProps, type JSX } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Fragment, jsx, jsxs } from 'solid-jsx/jsx-runtime';

import { clientLazy } from '~/utils/clientLazy';

const CopyButton = clientLazy(() => import('~/components/CopyButton'));
const LyricsTable = clientLazy(() => import('~/components/LyricsTable'));

const jsxOptions: Options = {
  components: {
    div(props) {
      return (
        <Show when={props['data-fake-paragraph']} fallback={<div {...props} />}>
          {props.children}
        </Show>
      );
    },
    a(props) {
      return <A {...props} />;
    },
    pre(props) {
      const [children, rest] = splitProps(props, ['children']);
      return (
        <pre {...rest}>
          {children.children}
          <CopyButton />
        </pre>
      );
    },
    table(props) {
      return (
        <Show
          when={
            !isServer &&
            (props.class?.includes('lyrics') || props.class?.includes('letra'))
          }
          fallback={<table {...props} />}
        >
          <LyricsTable {...props} />
        </Show>
      );
    },
  },
  Fragment,
  // @ts-expect-error: working for now
  jsx,
  // @ts-expect-error: working for now
  jsxs,
  elementAttributeNameCase: 'html',
  stylePropertyNameCase: 'css',
};

export function fragmentToJsx(
  fragment?: Nodes,
  plainText = false,
): JSX.Element {
  if (!fragment) {
    return <></>;
  }

  if (plainText) {
    return toString(fragment);
  }

  return toJsxRuntime(fragment, jsxOptions);
}
